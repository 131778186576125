import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./CookieConsent.scss";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the consent cookie is set
    const consent = Cookies.get("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Set the cookie consent and hide the banner
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-consent">
        <p>
          We use cookies to ensure you get the best experience on our website.
          By using our site, you consent to cookies. <a href="/privacy-policy">Learn more</a>
        </p>
        <button onClick={handleAccept}>Accept</button>
      </div>
    )
  );
};

export default CookieConsent;
