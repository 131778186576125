import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: 24-07-2024</p>
      <p>
        At alexkelemen.com, we are committed to protecting your privacy. This
        Privacy Policy explains how we collect, use, disclose, and safeguard
        your information when you visit our website. We do not collect any
        personal data from our visitors.
      </p>

      <h2>Information Collection and Use</h2>
      <p>
        We do not collect personal information from users visiting our site. Any
        information that may be collected automatically, such as your IP
        address, browser type, and operating system, is used solely for the
        purpose of improving the website experience and will not be shared with
        third parties.
      </p>

      <h2>Cookies</h2>
      <p>
        Our website may use cookies to enhance the user experience. Cookies are
        small files placed on your device that help us understand your
        preferences based on previous or current site activity. You can choose
        to disable cookies through your browser settings.
      </p>

      <h2>Third-Party Services</h2>
      <p>
        We do not use third-party services to collect or process data about our
        visitors.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
        <br />
        Email: Kelemen.laszlo994@gmail.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
