import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./Chat.scss";

const Chat = () => {
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const savedMessages = Cookies.get("chatMessages");
    if (savedMessages) {
      setChatMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    Cookies.set("chatMessages", JSON.stringify(chatMessages), { expires: 7 });
  }, [chatMessages]);

  useEffect(() => {
    if (isChatOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isChatOpen]);

  const sendMessage = async () => {
    if (!message) return;

    const userMessage = { role: "user", content: message };
    setChatMessages([...chatMessages, userMessage]);
    setMessage("");

    try {
      const response = await axios.post(
        "https://api-nryyx3ojba-uc.a.run.app/chat",
        {
          message,
        }
      );
      const botMessage = {
        role: "assistant",
        content: response.data.botMessage,
      };
      setChatMessages([...chatMessages, userMessage, botMessage]);
    } catch (error) {
      console.error("Error communicating with backend:", error);
    }
  };

  return (
    <div className="chat-app">
      <button
        className="open-chat-btn"
        onClick={() => setIsChatOpen(!isChatOpen)}
      >
        Chat
      </button>
      {isChatOpen && (
        <div className="chat-container">
          <button
            className="close-chat-btn"
            onClick={() => setIsChatOpen(false)}
          >
            &#10005;
          </button>
          <div className="chat-box">
            {chatMessages.map((msg, index) => (
              <div key={index} className={`chat-message ${msg.role}`}>
                <div className="message-content">{msg.content}</div>
              </div>
            ))}
          </div>
          <div className="input-box">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="1"
              placeholder="Type a message..."
            />
            <button className="send-btn" onClick={sendMessage}>
              &#9654;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
