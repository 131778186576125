import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { About, Footer, Header, Skills, Work } from "./container";
import { Navbar } from "./components";
import Cookies from "js-cookie";
import "./App.scss";
import Chat from "./Chat";
import CookieConsent from "./components/cookies/CookieConsent";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";

const App = () => {
  const [sitePreferences, setSitePreferences] = useState({
    theme: "light",
    language: "en",
  });

  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const savedPreferences = Cookies.get("sitePreferences");
    if (savedPreferences) {
      setSitePreferences(JSON.parse(savedPreferences));
    }
  }, []);

  useEffect(() => {
    Cookies.set("sitePreferences", JSON.stringify(sitePreferences), {
      expires: 7,
    });
  }, [sitePreferences]);

  useEffect(() => {
    if (isChatOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isChatOpen]);

  const toggleTheme = () => {
    setSitePreferences((prevPreferences) => ({
      ...prevPreferences,
      theme: prevPreferences.theme === "light" ? "dark" : "light",
    }));
  };

  const switchLanguage = () => {
    setSitePreferences((prevPreferences) => ({
      ...prevPreferences,
      language: prevPreferences.language === "en" ? "es" : "en",
    }));
  };

  return (
    <Router>
      <div className={`app ${sitePreferences.theme}`}>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <About />
                <Work />
                <Skills />
                <Chat isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} />
              </>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
};

export default App;
